import { Component, Inject } from "@angular/core";
import { ContactService } from "./contact.service";
import { AosToken } from "../animate-on-scroll/aos";
import { TaggingService } from "../../containers/main/tagging.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
  inputs: [
    "openingTime",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
})
export class ContactComponent {
  public fullName: string;
  public phoneNo: string;
  public fullNameEmail: string;
  public emailAddress: string;
  public message: string;
  public hideEmailFields: boolean = false;
  public hidePhoneFields: boolean = false;
  openingHour: boolean;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;

  constructor(
    private taggingService: TaggingService,
    @Inject(AosToken) aos,
    public contactService: ContactService
  ) {
    this.taggingService.setAllTags(
      "Contact Our Experts",
      "A software development agency based in London. We create digital solutions, big or small. Need to get in contact-page with our digital solution experts? Find our contact-page details here, or start a live chat."
    );
    aos.init();
  }

  submitContactRequest(type) {
    if (type === "Callback Request") {
      this.hidePhoneFields = true;
    } else {
      this.hideEmailFields = true;
    }
    this.contactService
      .sendEmail({
        email: this.emailAddress,
        fullName: type === "Email Request" ? this.fullName : this.fullNameEmail,
        phone: this.phoneNo,
        message: this.message,
      })
      .subscribe();
  }
}
