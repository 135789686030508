  import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "app-service-card-row",
  templateUrl: "./service-card-row.component.html",
  styleUrls: ["./service-card-row.component.scss"],
})
export class ServiceCardRowComponent implements OnInit {
  @ViewChild("carousel", { read: ElementRef }) public carousel: ElementRef<any>;

  public serviceCardInfo: {
    title: string;
    link: string;
    description: string;
    imageURL: string;
    buttonText: string;
  }[] = [];

  counter: number;
  showNavigationArrows = true;
  constructor(private http: HttpClient, private ref: ChangeDetectorRef) {}


  ngOnInit() {
    this.counter = 0;
      this.http.get("https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/pages?slug=data/services")
          .subscribe((servicePage: any) => {
        servicePage.result.populatedComponents.forEach(populatedComponent => {
          this.serviceCardInfo.push(populatedComponent.params);
        });

        console.log('services', this.serviceCardInfo);
        this.ref.detectChanges();
          const services = document.querySelectorAll("[id=service]");
          let max = 0;
          [].forEach.call(services, function (service) {
            if (service.offsetHeight) { max = max > service.offsetHeight ? max : service.offsetHeight; }
          });
          [].forEach.call(services, function (service) {
            service.style.height = max + 'px';
          });
          this.ref.detectChanges();
      });
    }
}
