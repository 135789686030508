<div class="router-outlet">
  <div class="full-width">
    <ul class="grid-x large-screen-menu">
      <li class="medium-2 columns" *ngFor="let menuItem of menuItems">
        <a [routerLink]="menuItem.link">
          {{menuItem.text}}
        </a>
      </li>
    </ul>
    <div class="grid-x small-screen-menu">
      <div class="small-12 columns">
        <a [routerLink]="'/book-services'">
          <img src="./assets/east-images/icons/calendar-outline.svg" width="20px" alt="Book an Appointment" />
          Book an Ultrasound Now
        </a>
      </div>
    </div>
  </div>
</div>
