import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

  @Input() public mapURL:string;
  @Input() public title:string
  @Input() public paragraph:string
  @Input() public telephone:string
  @Input() public imageURL:string

  constructor(public route: Router) { }

  ngOnInit(): void {
  }

}
