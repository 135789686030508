import { Component, Inject } from "@angular/core";
import { BookingServiceService } from "./bookingService.service";
import { AosToken } from "../animate-on-scroll/aos";
import { TaggingService } from "../../containers/main/tagging.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-booking-service",
  templateUrl: "./bookingService.component.html",
  styleUrls: ["./bookingService.component.scss"],
})
export class BookingServiceComponent {
  public fullName: string;
  public phoneNo: string;
  public fullNameEmail: string;
  public emailAddress: string;
  public address: string;
  // public dateOfBirth: string;
  public roomType: string;
  // public roomMate: string;
  public dietary: string;
  public injuries: string;
  public experience: string | boolean;
  public message: string;
  public isConfirmed: boolean = false;
  public hidePhoneFields: boolean = false;
  public serviceName: string;

  constructor(
    private taggingService: TaggingService,
    @Inject(AosToken) aos,
    public contactService: BookingServiceService,
    private activatedRoute: ActivatedRoute
  ) {
    this.taggingService.setAllTags(
      "Book a class now",
      "Book a yoga class with Alison Newsome now!"
    );
    aos.init();
    this.serviceName = this.activatedRoute.snapshot.queryParams.serviceName;

  }

  submitContactRequest(type) {
    this.isConfirmed = true;
    console.log("🚀 ~ BookingServiceComponent ~ submitContactRequest ~ experience:", this.experience)
    if (this.roomType) {
      this.roomType = "Private Room";
    } else  {
      this.roomType = "Sharing Room";
    }
    this.contactService
      .sendEmail({
        email: this.emailAddress,
        fullName: type === "Email Request" ? this.fullName : this.fullNameEmail,
        phone: this.phoneNo,
        message:
          // "Client address: " +
          // this.address +
          // this.address +
          // "\nDate of Birth:" +
          " Phone No: " +
          this.phoneNo +
          // this.dateOfBirth +
          " \nRoomType:" +
          this.roomType +
          // "\nRoom Mate:" +
          // this.roomMate +
          " \nDietary requirements" +
          this.dietary +
          "\nInjuries: " +
          this.injuries +
          " \nYoga Experience: " +
          (this.experience? 'Yes': 'No')
      })
      .subscribe();
  }
}
