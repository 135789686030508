<div class="card-container" #card>
    <div class="card-wrapper" id="review">

<!--        <div class="stars-container">-->
<!--            <i class="fa fa-star" aria-hidden="true" *ngIf="starCount1"></i>-->
<!--            <i class="fa fa-star" aria-hidden="true" *ngIf="starCount2"></i>-->
<!--            <i class="fa fa-star" aria-hidden="true" *ngIf="starCount3"></i>-->
<!--            <i class="fa fa-star" aria-hidden="true" *ngIf="starCount4"></i>-->
<!--            <i class="fa fa-star" aria-hidden="true" *ngIf="starCount5"></i>-->

<!--        </div>-->
        <div class="review-container" [innerHtml]="review"></div>
        <div class="review-header">
        <div class="user-information">
            <i class="fa fa-user-circle users-icon" aria-hidden="true"></i>
        </div>
        <div class="info">
            <h4>{{name}}</h4>
        </div>
    </div>
    </div>
</div>
