import { HttpClient } from '@angular/common/http';
import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-videos-row',
  templateUrl: './videos-row.component.html',
  styleUrls: ['./videos-row.component.css']
})
export class VideosRowComponent implements OnInit {


  public videoDetails: {
    title: string;
    paragraph: string;
    link: string;
  }[] = [];
  counter: number;
  trustedUrl: SafeUrl;

  constructor(private http: HttpClient, private ref: ChangeDetectorRef, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.counter = 0;
      this.http.get("https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/pages?slug=data/videos").subscribe((videoPage: any) => {
        // this.trustedUrl  = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + videoPage.link);

        videoPage.result.populatedComponents.forEach(populatedComponent => {
          populatedComponent.params.link = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + populatedComponent.params.link);
          this.videoDetails.push(populatedComponent.params);
          // this.videoDetails.push(populatedComponent.params.link);

          // console.log('trusted ', this.trustedUrl );
          console.log('video link ', this.videoDetails);
        });
      
        console.log('video details', this.videoDetails);
      
        this.ref.detectChanges();
          let reviews = document.querySelectorAll("[id=review]");
          let max = 0;
      
          [].forEach.call(reviews, function (review) {
            if (review.offsetHeight) max = max > review.offsetHeight ? max : review.offsetHeight;
          });
      
          [].forEach.call(reviews, function (review) {
            review.style.height = max + 'px';
          });
          this.ref.detectChanges();
      })
    }

}
