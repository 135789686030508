import { Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-image-with-textbox",
  templateUrl: "./image-with-textbox.component.html",
  styleUrls: ["./image-with-textbox.component.scss"],
})
export class ImageWithTextboxComponent {

  @Input() public header1: string;
  @Input() public paragraph1: string;
  @Input() public imageUrl1: string;
  @Input() public imageDescription1: string;
  @Input() public header2: string;
  @Input() public paragraph2: string;
  @Input() public imageUrl2: string;
  @Input() public imageDescription2: string;




  constructor(public route: Router) {}

}
