import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-flip-info-card",
  templateUrl: "./flip-info-card.component.html",
  styleUrls: ["./flip-info-card.css"],
  inputs: [
    "serviceTitle",
    "description",
    "price",
    "weeks",
    "imageUrl",
    "backgroundPosition",
    "textColor",
    "skewValue",
    "buttonText",
    "buttonLink",
    "buttonText2",
    "buttonLink2",
    "buttonText3",
    "buttonLink3",
    "buttonText4",
    "buttonLink4",
    "buttonText5",
    "buttonLink5",
    "hideArrow",
    "hideMargin",
  ],
})
export class FlipInfoCardComponent implements OnInit {
  public serviceTitle: string;
  public description: string;
  public price: string;
  public weeks: string;
  public imageUrl: string;
  public backgroundPosition: string;
  public textColor: string;
  public buttonText?: string;
  public buttonLink?: string;
  imageShow: boolean;

  public buttonText2?: string;
  public buttonLink2?: string;

  public buttonText3?: string;
  public buttonLink3?: string;

  public buttonText4?: string;
  public buttonLink4?: string;

  public buttonText5?: string;
  public buttonLink5?: string;
  public skewValue: number;
  public hideArrow?: boolean;
  public hideMargin?: boolean;
  public serviceid: string;

  isFront: boolean;
  isBack: boolean;

  constructor() {}

  ngOnInit() {
    this.serviceid = this.serviceTitle.replace(/\s/g, "");
    if (window.screen.width === 360) {
      // 768px portrait
      this.isBack = true;
      this.imageShow = false;
    }
  }

  showBackCard() {
    this.isFront = true;
  }

  showFrontCard() {
    this.isFront = false;
  }
}
