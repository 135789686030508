<!--<div class="card-wrapper">-->
<!--  <div class="card-outer grid-x">-->
<!--    <div class="description small-12 medium-8 columns">-->
<!--      <h2 class="header">Header</h2>-->
<!--      <p class="information">This scan is to exclude early fetal structural abnormalities mainly brain, heart, kidneys, bladder, abdominal wall, spine, and limbs. Fetal sex can also be determined at this stage. Early detection of abnormalities allows time for further tests and options to be decided at an early stage of pregnancy. Early Foetal Scan does not replace screening tests like NIPT, CVS, amniocentesis, or the routine 20 weeks anomaly scan.-->
<!--        <button class="button primary" (click)="toggleFlip()">See More..</button></p>-->
<!--    </div>-->

<!--    <div class="small-12 medium-8 columns">-->
<!--      <p>option list.. select one-->
<!--      </p>-->
<!--    </div>-->

<!--    <div class="image small-12 medium-4 columns">-->
<!--      <img src="assets/east-images/scanning.jpeg"/>-->
<!--      &lt;!&ndash;    <button>See more...</button>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->

<div class="padding">
  <div class="card-outer">
    <div class="card-wrapper">
      <div class="flip-card">
        <div class="flip-card-inner grid-x">
          <div class="image medium-4 columns">
            <img *ngIf="!imageShow" [src] = imageUrl />
<!--            <img src="assets/east-images/growthScan.jpeg">-->
          </div>
          <div class="flip-card-front small-12 medium-8 columns" *ngIf="!isFront"  (click)="showBackCard()">
            <div class="description">
<!--              <h4 class="header" [id]="serviceid">{{serviceTitle}}</h4>-->
              <h4 class="header" [id]="serviceid" >{{serviceTitle}}</h4>
              <span class="price"> <p class="weeks">  {{weeks}} @ {{price}}
            </p> </span>
              <p class="information">{{description}}</p>
                <a (click)="showBackCard()">Book Now</a>
              <!--        <p class="price">Info</p>-->
            </div>
          </div>
          <div *ngIf="isFront" (click)="showFrontCard()">
            <div class="flip-card-back small-12 medium-8 columns">
<!--              <h6 class="card-header">{{serviceTitle}}</h6>-->
<!--              <p class="card-description">{{description}}</p>-->
              <div class="button-section grid-x">
                <section class="small-12 medium-6 columns">
                  <a class="links" href="{{buttonLink}}">
                    <button class="button primary" *ngIf="buttonText">
                      {{buttonText}}
                    </button></a>
                </section>
                <section class="small-12 medium-6 columns">
                  <a class="links" href="{{buttonLink2}}">
                    <button class="button primary" *ngIf="buttonText2">
                      {{buttonText2}}
                    </button></a>
                </section>
                <section class="small-12 medium-6 columns">
                  <a class="links" href="{{buttonLink3}}">
                    <button class="button primary" *ngIf="buttonText3">
                      {{buttonText3}}
                    </button></a>
                </section>
                <section class="small-12 medium-6 columns">
                  <a class="links" href="{{buttonLink4}}">
                    <button class="button primary" *ngIf="buttonText4">
                      {{buttonText4}}
                    </button></a>
                </section>

                <section class="small-12 columns lastBtn">
                  <a class="links" href="{{buttonLink5}}">
                    <button class="button primary" *ngIf="buttonText5">
                      {{buttonText5}}
                    </button></a>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

