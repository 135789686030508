import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-service-card-slider",
  templateUrl: "./service-card-slider.component.html",
  styleUrls: ["./service-card-slider.component.scss"],
})
export class ServiceCardSliderComponent implements OnInit {
  @ViewChild("carousel", { read: ElementRef }) public carousel: ElementRef<any>;

  //TODO makes this with CMS
  public serviceCardInfo: {
    title: string;
    link: string;
    description:string;
    imageURL: string;
    buttonText: string;
  }[] = [
    {
      title: "Sports Massage",
      link: "men-and-women-services",
      description: "Improve your flexibility and the mobility in your joints as well as relieve muscle pain and help prevent future injury.\n",
      imageURL: "./assets/images/UpawardDog2.jpg",
      buttonText: "Book Now",
    },
    {
      title: "Pilates",
      link: "pregnancy-services",
      description: "Train your body as an integrated whole, focusing on core, lower body, and upper body strength as well as flexibility and posture. Book classes for beginner to advance.\n",
      imageURL: "./assets/images/pilates1.jpeg",
      buttonText: "Book Now",
    },
    {
      title: "Yoga",
      link: "non-invasive-services",
      description: "Develop inner awareness by focusing your attention on your body's abilities at the present moment. Get started on your Yoga journey with classes for beginner to advance.\n",
      imageURL: "./assets/images/yoga1.jpeg",
      buttonText: "Book Now",
    },
  ];

  counter: number;
  showNavigationArrows = true;
  constructor() {
    // this.startAutomaticScroll();
  }

  ngOnInit() {
    this.counter = 0;
  }

  startAutomaticScroll() {
    setInterval(() => {
      this.scrollRight();
    }, 4000);
  }

  scrollRight() {
    this.counter = 0;
    if (window.screen.width <= 375) {
      // tslint:disable-next-line:comment-format
      //@media (max-width: 375px)
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 375,
        behavior: "smooth",
      });
    } else if (window.screen.width <= 390) {
      // tslint:disable-next-line:comment-format
      //@media (max-width: 390px)
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 390,
        behavior: "smooth",
      });
    } else if (window.screen.width <= 414) {
      // tslint:disable-next-line:comment-format
      //@media (max-width: 414px)
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 207,
        behavior: "smooth",
      });
    } else if (window.screen.width <= 768) {
      // tslint:disable-next-line:comment-format
      //@media (max-width: 768px)
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 256,
        behavior: "smooth",
      });
    } else if (window.screen.width <= 820) {
      // tslint:disable-next-line:comment-format
      //@media (max-width: 820px)
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 272,
        behavior: "smooth",
      });
    } else {
      this.carousel.nativeElement.scrollTo({
        // tslint:disable-next-line:comment-format
        //Desktop screen
        left: this.carousel.nativeElement.scrollLeft + 540,
        behavior: "smooth",
      });
    }
  }

  scrollLeft() {
    this.carousel.nativeElement.scrollTo({
      left: this.carousel.nativeElement.scrollLeft - 300,
      behavior: "smooth",
    });
  }
}
