import { Component, OnInit, Input } from "@angular/core";
import { title } from "process";

@Component({
  selector: "app-our-values",
  templateUrl: "./our-values.component.html",
  styleUrls: ["./our-values.component.scss"],
})
export class OurValuesComponent implements OnInit {
  @Input() public imageURL: string;
  @Input() public paragraph: string;
  @Input() public imageURL2: string;
  @Input() public paragraph2: string;
  @Input() public imageURL3: string;
  @Input() public paragraph3: string;

  constructor() {}

  ngOnInit(): void {}
}
