<!----Component split---->
<div class="section-expect highlight" >
    <div class="grid-x grid-format">
        <div class="small-12 medium-6 columns text-holder">
<!--            <h2>Sports Massage Techniques</h2>-->
            <h2>{{sectionHeader1}}</h2>
<!--            <p>Some of the techniques used to treat conditions listed above include Friction, Muscle Energy Technique, Neuromuscular Technique, Trigger Point Therapy, Soft Tissue Release, Deep Tissue, Positional Release, Active Isolated Stretching.</p>-->
            <p [innerHtml]="paragraph1"></p>
        </div>
        <div class="small-12 medium-6 columns text-holder">
<!--            <h2>What to wear</h2>-->
            <h2>{{sectionHeader2}}</h2>
<!--            <p>Generally, sports clothes are preferred. If the lower part of the body is being treated, its recommended to wear loose fitting shorts or sports under garments. For upper body treatment, ladies should wear a sports bra and a sports T-shirt or vest, which can be adjusted during treatment.  Men should wear a sports T-shirt or vest. </p>-->
            <p [innerHtml]="paragraph2"></p>

        </div>
    </div>
</div>

<!----Component split---->
<div class="booking">
    <div class="grid-x booking-wrapper">
        <div class="table-wrap small-12 medium-6 columns">
            <table class="table-booking">
<!--                <thead>-->
<!--                <th>Service</th>-->
<!--                <th>Cost</th>-->
<!--                <th>Book Now</th>-->
<!--                </thead>-->

                <div *ngIf="sectionVisible === 'sports'">
                    <tr *ngFor="let service of sportsMassageServices">
                        <td>{{service.service}}</td>
                        <td>{{service.cost}}</td>
                        <td><a [href]="service.link"><button class="button" *ngIf="service.link">Book</button></a></td>
                    </tr>
                </div>
                <div *ngIf="sectionVisible === 'pilates'">
                    <tr *ngFor="let service of pilateServices">
                        <td>{{service.service}}</td>
                        <td>{{service.cost}}</td>
                        <td><a [href]="service.link"><button class="button" *ngIf="service.link">Book</button></a></td>
                    </tr>
                </div>
                <div *ngIf="sectionVisible === 'yoga'">
                    <tr *ngFor="let service of yogaServices">
                        <td>{{service.service}}</td>
                        <td>{{service.cost}}</td>
                        <td><a [href]="service.link"><button class="button" *ngIf="service.link">Book</button></a></td>
                    </tr>
                </div>
            </table>
        </div>
        <div class="notice-wrap small-12 medium-6 columns">
            <div class="learn-more">
                <app-staff-card-slider [small]="'true'" [hideSmallText]="'true'"></app-staff-card-slider>
            </div>
            <div class="notice" *ngIf="noticeTitle">
<!--                <h3>I offer more sessions at Southcote in address</h3>-->
                <h3>{{noticeTitle}}</h3>
<!--                <a href="https://goteamup.com/p/580983-pilates-through-life/"><button class="button">Book</button></a>-->
                <a [href]="noticeLink"><button class="button">{{buttonTitle}}</button></a>
            </div>
        </div>
    </div>

</div>
