import { DrMountisRedirectGuard } from './app-dr-mountis-redirect.guard';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BookingComponent } from './containers/booking/booking.component';

const routes: Routes = [
  {
    path: "our-videos",
    loadChildren: () =>
      import("./containers/videos/videos.module").then(
        (mod) => mod.VideosModule
      ),
  },
  // {
  //   path: "latest-articles",
  //   loadChildren: () =>
  //     import("./containers/articles/articles.module").then(
  //       (mod) => mod.ArticlesModule
  //     ),
  // },
  {
    path: "review-revu",
    loadChildren: () =>
      import("./containers/about-us/about-us.module").then(
        (mod) => mod.AboutUsModule
      ),
  },
  {
    path: "booking",
      loadChildren: () =>
        import("./containers/booking/booking.module").then((mod) => mod.BookingModule
        ),
  },
  {
    path: "retreats",
      loadChildren: () =>
        import("./containers/retreats/retreats.module").then((mod) => mod.RetreatsModule
        ),
  },
  // {
  //   path: "our-services",
  //   loadChildren: () =>
  //     import("./containers/our-services/our-services.module").then((mod) => mod.OurServicesModule
  //     ),
  // },
  // {
  //   path: "bookingService",
  //   loadChildren: () =>
  //     import("./containers/bookingService/bookingService.module").then(
  //       (mod) => mod.OurServicesModule
  //     ),
  // },
  // {
  //   path: "contact",
  //   loadChildren: () =>
  //     import("./containers/contact-page/contact-page.module").then(
  //       (mod) => mod.ContactPageModule
  //     ),
  // },
  // {
  //   path: "pmountis",
  //   canActivate: [DrMountisRedirectGuard],
  //   loadChildren: () =>
  //     import("./containers/pages/pages.module").then((mod) => mod.PagesModule),
  // },
  {
    path: "articles/:slug",
    loadChildren: () =>
      import("./containers/pages/pages.module").then((mod) => mod.PagesModule),
  },
  {
    path: ":slug",
    loadChildren: () =>
      import("./containers/pages/pages.module").then((mod) => mod.PagesModule),
  },
  // {
  //   path: "",
  //   loadChildren: () =>
  //       import("./containers/home/home.module").then(
  //           (mod) => mod.HomeModule
  //       ),
  // },
  {
    path: "",
    loadChildren: () =>
      import("./containers/pages/pages.module").then((mod) => mod.PagesModule),
  },
  { path: "**", redirectTo: "/", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
