/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { ServiceCardSliderComponent } from "./service-card-slider.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { StaffCardModule } from "../staff-card/staff-card.module";
import { ServiceCardsModule } from "../service-cards/service-cards.module";

@NgModule({
  imports: [CommonModule, RouterModule, StaffCardModule, ServiceCardsModule],
  entryComponents: [ServiceCardSliderComponent],
  declarations: [ServiceCardSliderComponent],
  exports: [ServiceCardSliderComponent],
})
export class ServiceCardSliderModule {}
