import { Component } from "@angular/core";

@Component({
  selector: "app-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.css"],
})
export class SubHeaderComponent {
  public menuItems = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "/about-us",
    },
    {
      text: "Our Services",
      link: "/book-services",
    },
    {
      text: "Our Team",
      link: "/videos",
    },
    {
      text: "Latest News",
      link: "/latest-news",
    },
    {
      text: "Contact Us",
      link: "/contact-page-us",
    },
  ];

  constructor() {}
}
