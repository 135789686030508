import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-jobs',
  templateUrl: './contact-jobs.component.html',
  styleUrls: ['./contact-jobs.component.scss']
})
export class ContactJobsComponent implements OnInit {

  @Input() public  header:string = 'Jobs';
  @Input() public  paragraph:string = ' If you would like to join our team here at Inline Health Ltd or are interested in taking part in events please e-mail us your CV, write about the therapy you offer and your availability. We would love to hear from you.';
  @Input() public  imgUrl:string = '/assets/images/brands/job-list.png';


  constructor() { }

  ngOnInit(): void {
  }
  
}
