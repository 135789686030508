/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { StaffCardSliderComponent } from "./staff-card-slider.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { StaffCardModule } from "../staff-card/staff-card.module";

@NgModule({
  imports: [CommonModule, RouterModule, StaffCardModule],
  entryComponents: [StaffCardSliderComponent],
  declarations: [StaffCardSliderComponent],
  exports: [StaffCardSliderComponent],
})
export class StaffCardSliderModule {}
