<div class="router-outlet">
    <div class="social-banner grid-x">
        <div class="social-caption small-6 medium-6 columns">
           <div class="social-icons">

               <a href="https://www.facebook.com/profile.php?id=61555699760414">
                   <i class="fa fa-facebook" aria-hidden="true"></i>
               </a>

               <a href="https://www.instagram.com/inlinehealth_1/">
                   <i class="fa fa-instagram" aria-hidden="true"></i>
               </a>
           </div>
        </div>
        <div class="button-section small-6 medium-offset-3 medium-3 columns">
            <div class="button-wrap">
                <div class="section-item button-section-right">
                    <button *ngIf="showBookNowButton" class="button book" routerLink="/booking">Book <span class="small-mobile-hide"> Now</span></button>
                </div>
            </div>
        </div>
    </div>
  <div class="full-width subfooter">
      <div class="footer-container grid-x">
          <div class="company-name small-12 medium-6 columns">
                  <h3>Inline Health</h3>
              <p>Welcome to Inline Health, a Sports Injury and Therapy Clinic, based in Loose, Maidstone.</p>

            <div class="badge-wrap">
                <a href="https://qualitybusinessawards.co.uk/2024/the-best-pilates-studio-in-maidstone/inline-health">
                <img class="badge" src="/assets/images/footer-badge.jpeg" width="100px"  alt="Inline Health" />
                </a>
            </div>

          </div>
          <div class="company-products small-12 medium-3 columns">
                  <h3>Services</h3>
              <p>
                    <a routerLink="/booking/yoga-pilates"  class="text-white">Yoga & Pilates</a>
              </p>
              <p>
                  <a  routerLink="/booking/massage" class="text-white" >Massage</a>
              </p>
              <!-- <p>
                  <a routerLink="/our-services-yoga"  class="text-white">Yoga</a>
              </p> -->
          </div>
          
          <div class="contact small-12 medium-3 columns">
                  <h3>Contact</h3>
              <p><i class="fas fa-home mr-3"></i>Maidstone, ME150HX</p>
              <a href = "tel: +447545187885"><p><i class="fas fa-phone mr-3"></i> 07545187885</p></a>
              <a href = "mailto: hello@inlinehealth.co.uk"><p><i class="fas fa-envelope mr-3"></i> hello&#64;inlinehealth.co.uk</p></a>
          </div>
      </div>
  </div>
    <div class="copyright text-white">
        <p class="east">© Inline Health. All rights reserved.
            <span>
            <br>
            </span> Created by <a href="https://createdigitalsolutions.com">Create Digital Solutions</a>.</p>
    </div>
</div>
