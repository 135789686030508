import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent implements OnInit {
  @Input() public header: string;
  @Input() public subheader: string;
  @Input() public backgroundImage: string;
  @Input() public body: string;
  @Input() public notice: string;
  @Input() public showWarning: boolean;
  @ViewChild("myModal", { read: ElementRef }) public myModal: ElementRef<any>;

  // showWarning:boolean;
  modal = document.getElementById("myModal");


  constructor() {
    // this.showWarning = true;
  }

  ngOnInit() {
  }


  //Close modal if you click outside of it.
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (event.target == document.getElementById("myModal")) {
      this.showWarning = false;
    }
  }

  //Close modal if you click escape.
  @HostListener("document:keydown", ["$event"])
  onDocumentKeydown(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.showWarning = false;
    }
  }

  openModal() {
    this.showWarning = !this.showWarning;
  }
}
