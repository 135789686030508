import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { BookingServiceComponent } from "./bookingService.component";
import { HeroModule } from "../hero/hero.module";
import { FormsModule } from "@angular/forms";
import { FourCardsModule } from "../four-cards/four-cards.module";

const routes: Routes = [
  {
    path: "",
    component: BookingServiceComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule {}

/**
 * The Module
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ContactRoutingModule,
    HeroModule,
    FourCardsModule,
  ],
  declarations: [BookingServiceComponent],
  exports: [BookingServiceComponent],
  providers: [],
})
export class BookingServiceModule {}
