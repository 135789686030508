/**
 * The Component Module
 */
import {NgModule} from "@angular/core";
import {GoogleCardComponent} from "./google-card.component";
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [GoogleCardComponent],
  declarations: [GoogleCardComponent],
  exports: [GoogleCardComponent]
})
export class GoogleCardModule {}
