
<div class="contact-wrapper">
  <div>
    <div class="contact-details">
      <div class="email-fields">
        <h3>Email Us</h3>
        <p>
          Pop us your contact details and a message, and we will be in touch in
          no time.
        </p>
        <div>
          <label for="fullNameEmail">Your name</label>
          <input
            type="text"
            name="fullNameEmail"
            id="fullNameEmail"
            placeholder="john doe"
            [(ngModel)]="fullNameEmail"
          />
          <label for="emailAddress">Your email address</label>
          <input
            type="text"
            name="emailAddress"
            id="emailAddress"
            placeholder="john-doe@mail.com"
            [(ngModel)]="emailAddress"
          />
          <label for="emailAddress">Your phone number</label>
          <input
            type="text"
            name="phoneNo"
            id="phoneNo"
            placeholder="123-123-1234"
            [(ngModel)]="phoneNo"
          />
          <label for="message">How can we help?</label>
          <textarea
            type="text"
            name="message"
            id="message"
            placeholder="Enter message here ..."
            [(ngModel)]="message"
          ></textarea>
        </div>
        <button
          (click)="submitContactRequest('Email Request')"
          class="button">
          Send Message
        </button>
        <h3 *ngIf="hideEmailFields">Thank you</h3>
        <p *ngIf="hideEmailFields">
          We'll be in touch shortly. We look forward to speaking with you.
        </p>
      </div>
    </div>
  </div>
</div>
