import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurValuesComponent } from './our-values.component';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [OurValuesComponent],
  declarations: [OurValuesComponent],
  exports: [OurValuesComponent]
})
export class OurValuesModule { }