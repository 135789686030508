import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-staff-card-slider",
  templateUrl: "./staff-card-slider.component.html",
  styleUrls: ["./staff-card-slider.component.scss"],
})
export class StaffCardSliderComponent implements OnInit {
  @ViewChild("carousel", { read: ElementRef }) public carousel: ElementRef<any>;

  @Input() public sliderDescription: string;
@Input() public small: string;
@Input() public hideSmallText: string;

  public teamMember: {
    name: string;
    imageUrl: string;
    role: string;
    studies?: string;
    qualifications?: string;
    paragraph1: string;
    paragraph2: string;
  }[] = [];
  counter: number;
  showNavigationArrows = true;
  segment: any;

  constructor(private http: HttpClient, private ref: ChangeDetectorRef) {
    // this.startAutomaticScroll();
  }

  ngOnInit() {
    this.counter = 0;

    this.http
      .get(
        "https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/pages?slug=data/teams"
      )
      .subscribe((teamsPage: any) => {
        teamsPage.result.populatedComponents.forEach((populatedComponent) => {
          this.teamMember.push(populatedComponent.params);
        });

        console.log("teamMember", this.teamMember);

        this.ref.detectChanges();
        let teams = document.querySelectorAll("[id=member]");
        let maxH = 0;
        let maxW = 0;

        [].forEach.call(teams, function (team) {
          if (team.offsetHeight)
            maxH = maxH > team.offsetHeight ? maxH : team.offsetHeight;
          if (team.offsetWidth)
            maxW = maxW > team.offsetWidth ? maxW : team.offsetWidth;
        });

        [].forEach.call(teams, function (team) {
          team.style.height = maxH + "px";
          team.style.width = maxW + "px";
        });

        // this.startAutomaticScroll();
        this.ref.detectChanges();
      });
  }

  // ngAfterViewInit(): void {
  //   let members = document.querySelectorAll("[id=member]");
  //   let max = 0;
  //
  //   [].forEach.call(members, function (member) {
  //     if (member.offsetHeight)
  //       max = max > member.offsetHeight ? max : member.offsetHeight;
  //   });
  //
  //   [].forEach.call(members, function (member) {
  //     member.style.height = max + "px";
  //   });
  // }

  startAutomaticScroll() {
    setInterval(() => {
      this.scrollRight();
    }, 5000);
  }

  scrollRight() {
    if (window.screen.width <= 400) {
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 400,
        behavior: "smooth",
      });
    } else {
      this.carousel.nativeElement.scrollTo({
        left: this.carousel.nativeElement.scrollLeft + 450,
        behavior: "smooth",
      });
    }
  }

  scrollLeft() {
    this.carousel.nativeElement.scrollTo({
      left: this.carousel.nativeElement.scrollLeft - 450,
      behavior: "smooth",
    });
  }

    changeSegment(section) {
        this.segment = section;
    }

  scrollSmoothToSection(targetId: string) {
    window.document.getElementById(targetId.split('#')[1]).scrollIntoView({ behavior: 'smooth'});
    return false;
  }
}


