import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowcaseComponent } from './showcase.component';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [ShowcaseComponent],
  declarations: [ShowcaseComponent],
  exports: [ShowcaseComponent]
})
export class ShowcaseModule { }

