import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {TaggingService} from '../../containers/main/tagging.service';
import {AosToken} from '../animate-on-scroll/aos';
import {ContactService} from '../contact/contact.service';

@Component({
  selector: 'app-contact-map',
  templateUrl: './contact-map.component.html',
  styleUrls: ['./contact-map.component.scss']
})
export class ContactMapComponent implements OnInit {
  
  @Input() public header: string;
  @Input() public address: string;
  @Input() public directionHeader: string;
  @Input() public directionOne: string;
  @Input() public directionTwo: string;
  @Input() public directionThree: string;
  @Input() public directionFour: string;
  @Input() public directionFive: string;

  public fullName: string;
  public phoneNo: string;
  public fullNameEmail: string;
  public emailAddress: string;
  public message: string;
  public hideEmailFields: boolean = false;
  public hidePhoneFields: boolean = false;

  showContact: boolean;
  constructor( private taggingService: TaggingService,
               @Inject(AosToken) aos,
               public contactService: ContactService) {}

  //Close modal if you click outside of it.
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (event.target == document.getElementById("myModal")) {
      this.showContact = false;
    }
  }

  //Close modal if you click escape.
  @HostListener("document:keydown", ["$event"])
  onDocumentKeydown(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.showContact = false;
    }
  }

  openModal() {
    this.showContact = !this.showContact;
  }
  ngOnInit(): void {
  }


  submitContactRequest(type) {
    // if (type === "Callback Request") {
    //   this.hidePhoneFields = true;
    // } else {
    //   this.hideEmailFields = true;
    // }
    this.contactService
        .sendEmail({
          email: this.emailAddress,
          fullName: type === "Email Request" ? this.fullName : this.fullNameEmail,
          phone: this.phoneNo,
          message: this.message,
        })
        .subscribe(() => {
          this.hideEmailFields = true;
          console.log('email sent')
        });
  }
}
