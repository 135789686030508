import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public imageURL = './assets/images/logo-trans.png';
  public dropdown: {
    header: {
      name: string;
      link?: string;
      subheader?: { name: string; link: string }[];
    }[];
  } = {
    header: [
      { name: 'Home', link: '/' },
      {
        name: 'Services',
        link: '/our-services',
        // subheader: [
        //   { name: "Sports Massage", link: "/men-and-women-services" },
        //   { name: "Pilates", link: "/pregnancy-services" },
        //   { name: "Yoga", link: "/non-invasive-services" },
        // ],
      },
      {
        name: 'Booking',
        link: '/booking',
        subheader: [
          { name: "Yoga & Pilates", link: "/booking/yoga-pilates" },
          { name: "Massage", link: "/booking/massage"},
        ],
      },
      // { name: 'Videos', link: '/our-videos' },
      { name: 'Retreats', link: '/retreats' },
      { name: 'About', link: '/about-alison' },
      { name: 'Contact', link: '/contact-us' },
    ],
  };

  public fixedPosition: boolean =
    window.pageYOffset > 106 || window.innerWidth < 520;
  public logoWidth: string = this.fixedPosition ? '200px' : '400px';

  constructor(public route: Router) {}

  ngOnInit() {
    window.addEventListener('scroll', this.checkIfShouldFix, true);
    window.addEventListener('resize', this.checkIfShouldFix, true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.checkIfShouldFix, true);
    window.removeEventListener('resize', this.checkIfShouldFix, true);
  }

  checkIfShouldFix = (): void => {
    this.fixedPosition = window.pageYOffset > 106 || window.innerWidth < 520;
  };
}
