<div class="video-wrapper" id="video">
    <h1 class="main-heading">My Videos</h1>
    <p><a href="/booking">Book in</a> now to join Alison in yoga or pilates classes. Here are some clips you can do from home. </p>
    <div class="grid-x">
        <div class="video-wrap small-12 medium-4 columns" *ngFor="let video of videoDetails">
            <iframe height="350px" [src]="video.link" [title]="video.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <h2>{{ video.title }}</h2>
            <p [innerHtml]="video.paragraph"></p>
        </div>
    </div>
</div>
