import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-google-card",
  templateUrl: "./google-card.component.html",
  styleUrls: ["./google-card.component.scss"],
})
export class GoogleCardComponent implements OnInit {
  @ViewChild("card", { read: ElementRef }) public card: ElementRef<any>;
  @Input() public name: string;
  @Input() public username?: string;
  @Input() public dateTime?: string;
  @Input() public stars: number;
  @Input() public review: string;
  starCount1: boolean;
  starCount2: boolean;
  starCount3: boolean;
  starCount4: boolean;
  starCount5: boolean;
  halfstarCount: boolean;

  public totalStars: number;
  public starsCounter: number;
  constructor() {}

  ngOnInit(): void {
    this.fillStars();
  }

  fillStars() {
    if (this.stars === 5) {
      this.starCount1 = true;
      this.starCount2 = true;
      this.starCount3 = true;
      this.starCount4 = true;
      this.starCount5 = true;
    } else if (this.stars === 4) {
      this.starCount1 = true;
      this.starCount2 = true;
      this.starCount3 = true;
      this.starCount4 = true;
      this.starCount5 = false;
    } else if (this.stars === 3) {
      this.starCount1 = true;
      this.starCount2 = true;
      this.starCount3 = true;
      this.starCount4 = false;
      this.starCount5 = false;
    } else if (this.stars === 2) {
      this.starCount1 = true;
      this.starCount2 = true;
      this.starCount3 = false;
      this.starCount4 = false;
      this.starCount5 = false;
    } else if (this.stars === 1) {
      this.starCount1 = true;
      this.starCount2 = false;
      this.starCount3 = false;
      this.starCount4 = false;
      this.starCount5 = false;
    } else {
      this.starCount1 = false;
      this.starCount2 = false;
      this.starCount3 = false;
      this.starCount4 = false;
      this.starCount5 = false;
    }
  }
  getHeight() {
    var height = this.card.nativeElement.offsetHeight;
    console.log("Height:" + height);
    return height;
  }
}
