import { Component, Input } from "@angular/core";

@Component({
  selector: "app-hero-about",
  templateUrl: "./hero-about.component.html",
  styleUrls: ["./hero-about.component.scss"],
})
export class HeroAboutComponent {
  @Input() public header: string;
  @Input() public subheader: string;
  @Input() public body: string;
  @Input() public subbody: string;
  @Input() public imageUrl: string;



  constructor() {}
}
