/**
 * The Component Module
 */
import {NgModule} from "@angular/core";
import {ContactJobsComponent} from "./contact-jobs.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [ContactJobsComponent],
  declarations: [ContactJobsComponent],
  exports: [ContactJobsComponent]
})
export class ContactJobsModule {}
