/**
 * The Component Module
 */
import {NgModule} from "@angular/core";
import {ContactMapComponent} from "./contact-map.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ContactModule} from '../contact/contact.module';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [CommonModule, RouterModule,  FormsModule, ContactModule],
  entryComponents: [ContactMapComponent],
  declarations: [ContactMapComponent],
  exports: [ContactMapComponent]
})
export class ContactMapModule {}
