
<div class="container">
    <div class="wrapper">
        <div class="section-intro">
            <div class="grid-x grid-format">
                <div class="small-12 medium-6 columns image-holder" [ngStyle]="{'background-image': 'url(' + imageUrl1 + ')'}">
<!--                    <img [src]="imageUrl1" [alt]="imageDescription1" class="full-width-image"/>-->
                </div>
                <div class="small-12 medium-6 columns text-holder">
                    <h2 class="title">{{header1}}</h2>
                    <p class="mb-0" [innerHtml]="paragraph1"></p>
                </div>
            </div>
        </div>
        <div class="section-benefits highlight" >
            <div class="grid-x grid-format">
                <div class="small-12 medium-6 columns text-holder">
                    <h2 class="title">{{header2}}</h2>
                    <p [innerHtml]="paragraph2"></p>
                </div>
                <div class="small-12 medium-6 columns image-holder" [ngStyle]="{'background-image': 'url(' + imageUrl2 + ')'}">
<!--                    <img [src]="imageUrl2" [alt]="imageDescription2" class="full-width-image"/>-->
                </div>
            </div>
        </div>
    </div>
</div>
