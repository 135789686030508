<nav class="nav">
  <ul class="nav-list">
    <div
      [ngClass]="{
        open: MenuOpen,
        close: !MenuOpen && menuInteractionStarted
      }"
      (click)="toggleMenu()"
      class="burger-menu"
      class="menu-btn"
    >
      <div class="menu-btn__burger"></div>
    </div>
    <li class="nav-list-item" *ngFor="let header of dropdown.header">
      <a [ngClass]="{ 'color-text': maxPosition }" [routerLink]="header.link">{{
        header.name
      }}</a>
      <ul class="nav-list-item-drop">
        <li *ngFor="let subheader of header.subheader">
          <a [routerLink]="subheader.link">{{ subheader.name }}</a>
        </li>
      </ul>
    </li>
    <!-- Badge -->
    <li class="nav-list-badge">
      <div class="badge-wrap">
        <a href="https://qualitybusinessawards.co.uk/2024/the-best-pilates-studio-in-maidstone/inline-health">
          <img class="badge" [src]="badgeURL" alt="Inline Health" />
        </a>
      </div>
    </li>

    <li class="nav-list-logo" routerLink="/">
      <div class="logo-wrap">
        <img class="logo" routerLink="/" [src]="imageURL" alt="Inline Health" />

      </div>
    </li>
  </ul>
</nav>

<div
  [ngClass]="{
    open: MenuOpen,
    'open-menu': burgerMenuOpen,
    'close-menu': !burgerMenuOpen && menuInteractionStarted,
    close: !MenuOpen && menuInteractionStarted
  }"
  class="burger-menu"
>
  <li *ngFor="let header of dropdown.header; let i = index">
    <ul class="menu" (click)="show(i)">
      <a
        class="burger-menu-item"
        [routerLink]="header.link"
        (click)="closeBurger(header); this.MenuOpen = false"
        >{{ header.name }}</a
      >
      <i
        *ngIf="header.subheader"
        class="fa fa-angle-down dropdown-icon"
        aria-hidden="true"
      ></i>
    </ul>
    <ul class="submenu" *ngIf="header.show">
      <li *ngFor="let subheader of header.subheader">
        <a
          class="burger-menu-item"
          [routerLink]="subheader.link"
          (click)="burgerMenuOpen = false"
          >{{ subheader.name }}</a
        >
      </li>
    </ul>
  </li>
</div>
