import { Component, Input } from "@angular/core";

@Component({
  selector: "app-showcase",
  templateUrl: "./showcase.component.html",
  styleUrls: ["./showcase.component.scss"],
})
export class ShowcaseComponent {
  @Input() imageURL: string;
  @Input() showcase: {
    imageURL: string;
    title: {
      name: string;
      paragraph: string;
      subtitle: { name: string; paragraph?: string; imageURL: string }[];
    }[];
  } = {
    imageURL: "./assets/east-images/ultrasounds.png",
    title: [
      {
        name: "Seamless, Secure",
        paragraph:
          "We provide scan images directly to your smartphone, often on the same day following your appointment. Gain the peace of mind and insight you need, without delay.",

        subtitle: [
          {
            name: "Safe to Share",
            imageURL: "./assets/east-images/icons8-connect.png",
          },
          {
            name: "On Time",
            imageURL: "./assets/east-images/icons8-timely.png",
          },
          {
            name: "Same-day Results",
            imageURL: "./assets/east-images/icons-sameday.png",
          },
        ],
      },
    ],
  };

  constructor() {}
}
