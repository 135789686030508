
<div class="review-container">
  <div class="review-header">
    <img src="./assets/images/edited/reviews.png" class="review-image"/>
    <h3>Testimony</h3>
    <h1 class="main-heading">Successful story</h1>
  </div>
  <div class="outer-wrapper">
    <div class="carousel" [ngClass]="{ overflow: overflowHidden }" #carousel>
      <div class="grid-x">
        <ng-container
                *ngFor="let user of userReviews; let i = index"
                (click)="reviewCounter = i"
        >
          <app-google-card
                  #card
                  class="review-item small-12 medium-12 large-12"
                  [name]="user.name"
                  [username]="user.username"
                  [dateTime]="user.dateTime"
                  [stars]="user.stars"
                  [review]="user.review"
          ></app-google-card>
        </ng-container>
      </div>
    </div>
    <div class="slide-arrows">
      <i
              class="fa fa-chevron-down move-icons"
              style="padding-left: 20px"
              aria-hidden="true"
              (click)="scrollUp(); setOverflow()"
      ></i>
    </div>
  </div>
</div>
