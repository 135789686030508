<div id="services" class="background-image-container">
  <div class="grid-x row-container">
    <div class="image-holder small-12 medium-6 columns">
      <img src="./assets/images/edited/ali-services.png" class="service-image"/>
    </div>
    <div class="service-holder small-12 medium-6 columns">
      <div class="section-header">
        <h1 class="main-heading">Services we provide</h1>
      </div>
      <div class="grid-x service-cards">
        <div *ngFor="let service of serviceCardInfo" class="card small-12 medium-6 columns">

          <app-service-cards
                  [title]="service.title"
                  [link]="service.link"
                  [description]="service.description"
                  [imageURL]="service.imageURL"
                  [buttonText]="service.buttonText"
                  [ngClass]="{ active: counter }"
          ></app-service-cards>

        </div>
      </div>
    </div>

  </div>
</div>
