<div>
  <div class="map" id="find">
    <h2>{{ header }}</h2>
    <iframe
      src="https://www.google.com/maps/embed/v1/place?q=2+Stockett+Lane,+Maidstone,+ME150HX&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
      width="100%"
      height="450"
      frameborder="0"
      allowfullscreen=""
    ></iframe>
    <p class="address">
      {{ address }}
    </p>
  </div>
  <div class="directions">
    <h3>
      {{ directionHeader }}
    </h3>
    <ul class="direction-list">
      <li>{{ directionOne }}</li>
      <li>
        {{ directionTwo }}
      </li>
      <li>
        {{ directionThree }}
      </li>
      <li>
        {{ directionFour }}
      </li>
      <li>
        {{ directionFive }}
      </li>
    </ul>
    <div class="call-to">
      <button class="button" (click)="openModal()">Contact Us Now</button>
    </div>
  </div>
</div>

<div class="contact">
  <div *ngIf="showContact" id="myModal" #myModal class="modal">
    <!-- Modal content -->

    <div class="modal-content">
      <span (click)="openModal()" class="close">&times;</span>

      <div class="contact-wrapper">
        <div>
          <div class="contact-details">
            <div class="email-fields"  *ngIf="!hideEmailFields">
              <h3>Email Uss</h3>
              <p>
                Pop us your contact details and a message, and we will be in touch in
                no time.
              </p>
              <div>
                <label for="fullNameEmail">Your name</label>
                <input
                        type="text"
                        name="fullNameEmail"
                        id="fullNameEmail"
                        placeholder="john doe"
                        [(ngModel)]="fullNameEmail"
                />
                <label for="emailAddress">Your email address</label>
                <input
                        type="text"
                        name="emailAddress"
                        id="emailAddress"
                        placeholder="john-doe@mail.com"
                        [(ngModel)]="emailAddress"
                />
                <label for="emailAddress">Your phone number</label>
                <input
                        type="text"
                        name="phoneNo"
                        id="phoneNo"
                        placeholder="123-123-1234"
                        [(ngModel)]="phoneNo"
                />
                <label for="message">How can we help?</label>
                <textarea
                        type="text"
                        name="message"
                        id="message"
                        placeholder="Enter message here ..."
                        [(ngModel)]="message"
                ></textarea>
              </div>
              <button
                      (click)="submitContactRequest('Email Request')"
                      class="button">
                Send Message
              </button>
              <h3 *ngIf="hideEmailFields">Thank you</h3>
              <p *ngIf="hideEmailFields">
                We'll be in touch shortly. We look forward to speaking with you.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
