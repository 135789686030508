import { Component, Input } from "@angular/core";

@Component({
  selector: "app-four-cards",
  templateUrl: "./four-cards.component.html",
  styleUrls: ["./four-cards.component.scss"],
})
export class FourCardsComponent {
  @Input() public sectionTitle?: string;

  @Input() public title1: string;
  @Input() public paragraph1?: string;

  @Input() public title2: string;
  @Input() public paragraph2?: string;

  @Input() public title3: string;
  @Input() public paragraph3?: string;

  @Input() public title4: string;
  @Input() public paragraph4?: string;

  @Input() public title5: string;
  @Input() public paragraph5?: string;

  @Input() public title6: string;
  @Input() public paragraph6?: string;

  constructor() {}
}
