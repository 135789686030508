import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-staff-card",
  templateUrl: "./staff-card.component.html",
  styleUrls: ["./staff-card.component.scss"],
})
export class StaffCardComponent implements OnInit {
  @ViewChild("myModal", { read: ElementRef }) public myModal: ElementRef<any>;

  showList = false;
  modal = document.getElementById("myModal");

  @Input() public name: string;
  @Input() public imageUrl: string;
  @Input() public education: string;
  @Input() public description: string;
  @Input() public extraDescription?: string;
  @Input() public specialism1?: string;
  @Input() public specialism1Link?: string;
  @Input() public specialism2?: string;
  @Input() public specialism2Link?: string;
  @Input() public specialism3?: string;
  @Input() public specialism3Link?: string;
  @Input() public specialism4?: string;
  @Input() public specialism4Link?: string;
  @Input() public specialism5?: string;
  @Input() public specialism5Link?: string;

  constructor() {}

  ngOnInit(): void {}

  //Close modal if you click outside of it.
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (event.target == document.getElementById("myModal")) {
      this.showList = false;
    }
  }

  //Close modal if you click escape.
  @HostListener("document:keydown", ["$event"])
  onDocumentKeydown(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.showList = false;
    }
  }

  fireEvent(e) {
    console.log(e.type);
  }

  openModal() {
    this.showList = !this.showList;
  }
}
