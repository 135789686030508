<div>
  <a (click)="toggleMenu()">
    <img src="./assets/east-images/icons/menu-outline.png" width="40px" class="burger-icon" alt="Open Main Menu" />
  </a>
  <div class="burger-menu" [ngClass]="{'open-menu': burgerMenuOpen, 'close-menu': !burgerMenuOpen && menuInteractionStarted}">
    <a (click)="toggleMenu()">
      <img src="./assets/east-images/icons/close-icon.png" width="20px" class="close-menu-icon" alt="Close Main Menu" />
    </a>

    <nav class="vertical-center">
      <ul>
        <li class="handwriting-font medium-font" *ngFor="let menuItem of menuItems">
          <a [routerLink]="menuItem.link" (click)="toggleMenu()">
            {{menuItem.text}}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

