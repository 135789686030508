<div class="hero-container">
  <div class="background-wrapper">
    <div class="text-wrapper">
      <h2>{{topText}} </h2>
<!--      <h1 class="brand-gradient">{{largeText}}</h1>-->
      <h1> <img src="./assets/east-images/logo.png" alt="logo" class="logo" /></h1>
      <h2>{{bottomText}}</h2>
      <p [innerHtml]="paragraph"></p>
    </div>
    <div class="service-cards">
      <app-service-card-slider></app-service-card-slider>
    </div>
  </div>
</div>
