import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendEmail(requestBodyData: object) {
    return this.http.post(
      "https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/emails/inbound",
      requestBodyData,
      { observe: "response" }
    );
  }
}
