<div class="container">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120"><path fill="#fff" fill-opacity="100000" d="M0,96L80,96C160,96,320,96,480,85.3C640,75,800,53,960,53.3C1120,53,1280,75,1360,85.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg> -->
    <div class="wrapper grid-x">
        <div class="text-information small-12 medium-6 columns" *ngFor="let title of showcase.title">
            <div>
                <h1>{{title.name}}</h1>
                <p>{{title.paragraph}}</p>
                
           </div>
            <div class="inner-columns flex-block" >
                <div *ngFor="let subtitle of title.subtitle">
                    <img src="{{subtitle.imageURL}}"  class="icons"/>
                    <h4 class="small-headings">{{subtitle.name}}</h4>
                    <p>{{subtitle.paragraph}}</p>
                </div>
            </div>
        </div>
        <div class="small-12 medium-6 columns">
            <div class="image-wrapper" *ngFor="let title of showcase.title">
<!--                 <img src="{{showcase.imageURL}}" alt="image" class="image"/>-->
                <div class="image" [ngStyle]="{'background-image':'url('+showcase.imageURL+')'}"></div>
            </div>
        </div>
    </div>
    <!-- <svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120"><path fill="#fff" fill-opacity="100000" d="M0,96L80,96C160,96,320,96,480,85.3C640,75,800,53,960,53.3C1120,53,1280,75,1360,85.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg> -->
</div>
