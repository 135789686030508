import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookingService-table',
  templateUrl: './booking-table.component.html',
  styleUrls: ['./booking-table.component.scss']
})
export class BookingTableComponent implements OnInit {

  @Input() public firstTableRowHeader:string;
  @Input() public secondTableRowHeader:string;
  @Input() public thirdTableRowHeader:string;
  @Input() public service:string;
  @Input() public cost:string;
  @Input() public link:string;
  @Input() public extraHeader:string;
  @Input() public extraParagraph:string;
  @Input() public extra:string;
  @Input() public extraLink:string;

  //Add to cms like services
  public bookingDetail: {
    service: string;
    cost?: string;
    link?: string;
    extra?: string;
  } [] = [] ;
  counter: number;

  constructor(private http: HttpClient, private ref: ChangeDetectorRef) {}


  ngOnInit() {
    this.counter = 0;
      this.http.get("https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/pages?slug=data/classes").subscribe((classesPage: any) => {
        classesPage.result.populatedComponents.forEach(populatedComponent => {
          this.bookingDetail.push(populatedComponent.params);
        });
      
        console.log('services', this.bookingDetail)
      
        this.ref.detectChanges();
          let reviews = document.querySelectorAll("[id=review]");
          let max = 0;
      
          [].forEach.call(reviews, function (review) {
            if (review.offsetHeight) max = max > review.offsetHeight ? max : review.offsetHeight;
          });
      
          [].forEach.call(reviews, function (review) {
            review.style.height = max + 'px';
          });
          this.ref.detectChanges();
      })
    }

  scrollSmoothToSection(targetId: string) {
    window.document.getElementById(targetId.split('#')[1]).scrollIntoView({ behavior: 'smooth'});
    return false;
  }
}
