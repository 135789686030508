/**
 * The Component Module
 */
import {NgModule} from "@angular/core";
import {BookingTableComponent} from "./booking-table.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ServiceCardSliderModule} from '../service-card-slider/service-card-slider.module';
import {StaffCardSliderModule} from '../staff-card-slider/staff-card-slider.module';

@NgModule({
    imports: [CommonModule, RouterModule, ServiceCardSliderModule, StaffCardSliderModule],
  declarations: [BookingTableComponent],
  exports: [BookingTableComponent]
})
export class BookingTableModule {}
