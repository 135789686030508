import { Component } from "@angular/core";

@Component({
  selector: "app-nav",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent {
  public menuItems = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "/about-us",
    },
    {
      text: "Services",
      link: "/book-services",
    },
    {
      text: "Retreats",
      link: "/retreats",
    },
    {
      text: "Our Team",
      link: "/videos",
    },
    {
      text: "Latest News",
      link: "/latest-news",
    },
    {
      text: "Contact Us",
      link: "/contact-page-us",
    },
  ];

  public burgerMenuOpen: boolean = false;
  public menuInteractionStarted: boolean = false;

  constructor() {}

  toggleMenu() {
    this.menuInteractionStarted = true;
    this.burgerMenuOpen = !this.burgerMenuOpen;
  }
}
