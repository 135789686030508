import { Component, Input } from "@angular/core";

@Component({
  selector: "app-service-cards",
  templateUrl: "./service-cards.component.html",
  styleUrls: ["./service-cards.component.scss"],
})
export class ServiceCardsComponent {

   @Input() public title: string;
   @Input() public link: string;
   @Input() public description: string;
   @Input() public imageURL: string;
   @Input() public buttonText?: string;
   @Input() public floatDirection?: string;

  constructor() {}
}
