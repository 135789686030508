/**
 * The Component Module
 */
 import { NgModule } from "@angular/core";
 import { NavigationModule } from "../navigation/navigation.module";
 import { CommonModule } from "@angular/common";
 import { RouterModule } from "@angular/router";
import { ImageWithTextboxComponent } from "./image-with-textbox.component";
 
 @NgModule({
   imports: [
     NavigationModule,
     CommonModule,
     RouterModule,
   ],
   entryComponents: [ImageWithTextboxComponent],
   declarations: [ImageWithTextboxComponent],
   exports: [ImageWithTextboxComponent],
 })
 export class ImageWithTextboxModule {}
