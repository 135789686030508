/**
 * The Component Module
 */
 import { NgModule } from "@angular/core";
 import { NavigationModule } from "../navigation/navigation.module";
 import { CommonModule } from "@angular/common";
 import { RouterModule } from "@angular/router";
 import { GoogleMapComponent } from "./google-map.component";
 
 
 
 @NgModule({
   imports: [
     NavigationModule,
     CommonModule,
     RouterModule,
   ],
   entryComponents: [GoogleMapComponent],
   declarations: [GoogleMapComponent],
   exports: [GoogleMapComponent],
 })
 export class GoogleMapModule {}
 