import { ServiceCardRowModule } from 'src/app/components/service-card-row/service-card-row.module';
import { BookingTableModule } from 'src/app/components/booking-table/booking-table.module';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";
import { AppRoutingModule } from "./app-routing.module";

import { MainComponent } from "./containers/main/main.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AosToken, aos } from "./components/animate-on-scroll/aos";
import { HeaderModule } from "./components/header/header.module";
import { FooterModule } from "./components/footer/footer.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SubHeaderModule } from "./components/sub-header/sub-header.module";
import { FlipInfoCardModule } from "./components/flip-info-card/flip-info-card.module";
import { HeroServiceModule } from "./components/hero-service/hero-service.module";
import { GoogleReviewSliderModule } from "./components/google-review-slider/google-review-slider.module";
import { StaffCardSliderModule } from "./components/staff-card-slider/staff-card-slider.module";
import { FlexibilityModule } from "./components/flexibility/flexibility.module";
import { OurValuesModule } from "./components/our-values/our-values.module";
import { HeroAboutModule } from "./components/hero-about/hero-about.module";
import { ContactModule } from "./components/contact/contact.module";
import { ContactPageModule } from "./containers/contact-page/contact-page.module";
import { ShowcaseModule } from "./components/showcase/showcase.module";
import { GoogleMapModule } from "./components/google-map/google-map.module";
import { ImageWithTextboxModule } from "./components/image-with-textbox/image-with-textbox.module";
import { ContactJobsModule } from "./components/contact-jobs/contact-jobs.module";
import { ContactMapModule } from "./components/contact-map/contact-map.module";
import { ContactHeaderModule } from "./components/contact-header/contact-header.module";
// import { BookingTableModule } from "./components/bookingService-table/bookingService-table.module";
import { VideosRowModule } from "./components/videos-row/videos-row.module";
import {ImageAndDescriptionTwoModule} from './components/image-and-description-two/image-and-description-two.module';
import {BookingServiceModule} from './components/bookingService/bookingService.module';

declare global {
  interface Window {
    LC_API: any;
  }
}

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HeaderModule,
    FlipInfoCardModule,
    SubHeaderModule,
    FooterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB8pJ-LN7LlY5MwOvPYsrMEyo92rfJHWFY',
    }),
    HeroServiceModule,
    GoogleReviewSliderModule,
    StaffCardSliderModule,
    FlexibilityModule,
    OurValuesModule,
    HeroAboutModule,
    ContactPageModule,
    ContactModule,
    ShowcaseModule,
    StaffCardSliderModule,
    GoogleMapModule,
    ImageWithTextboxModule,
    ContactJobsModule,
    ContactMapModule,
    ContactHeaderModule,
    BookingTableModule,
    VideosRowModule,
    ServiceCardRowModule,
      BookingServiceModule,
    ImageAndDescriptionTwoModule
  ],
  providers: [{provide: AosToken, useValue: aos}],
  bootstrap: [MainComponent],
  exports: []
})
export class AppModule {}
