import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { ContactPageComponent } from "./contact-page.component";
import { ContactModule } from "../../components/contact/contact.module";
import { ContactHeaderModule } from "src/app/components/contact-header/contact-header.module";
import { ContactMapModule } from "src/app/components/contact-map/contact-map.module";
import { ContactJobsModule } from "src/app/components/contact-jobs/contact-jobs.module";

const routes: Routes = [
  {
    path: "",
    component: ContactPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactPageRoutingModule {}

/**
 * The Module
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ContactPageRoutingModule,
    ContactModule,
     ContactJobsModule,
    ContactMapModule,
    ContactHeaderModule

  ],
  declarations: [ContactPageComponent],
  providers: [],
})
export class ContactPageModule {}
