/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { NavigationModule } from "../navigation/navigation.module";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ImageAndDescriptionTwoComponent } from "./image-and-description-two.component";
import {StaffCardSliderModule} from '../staff-card-slider/staff-card-slider.module';

@NgModule({
    imports: [NavigationModule, CommonModule, RouterModule, StaffCardSliderModule],
  // entryComponents: [ImageAndDescriptionTwoComponent],
  declarations: [ImageAndDescriptionTwoComponent],
  exports: [ImageAndDescriptionTwoComponent],
})
export class ImageAndDescriptionTwoModule {}
