import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
} from "@angular/core";

@Component({
  selector: "app-google-review-slider",
  templateUrl: "./google-review-slider.component.html",
  styleUrls: ["./google-review-slider.component.scss"],
})
export class GoogleReviewSliderComponent implements OnInit {
  @ViewChild('carousel', { read: ElementRef }) public carousel: ElementRef<any>;
  @ViewChild('card', { read: ElementRef }) public card: ElementRef<any>;

  public userReviews: {
    name: string;
    username: string;
    dateTime: string;
    stars: number;
    review: string;
  }[] = [];

  overflowHidden: boolean;
  reviewCounter: number;
  showNavigationArrows = true;
  constructor(private http: HttpClient, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.reviewCounter = 0;
    this.http.get("https://api2.createdigitalsolutions.com/websites/62e143669bc4bc1dab047200/pages?slug=data/reviews").subscribe((reviewPage: any) => {
      reviewPage.result.populatedComponents.forEach(populatedComponent => {
        populatedComponent.params.stars = Number(populatedComponent.params.stars);
        this.userReviews.push(populatedComponent.params);
      });
    
      console.log('reviews', this.userReviews)
    
      this.ref.detectChanges();
        let reviews = document.querySelectorAll("[id=review]");
        let max = 0;
    
        [].forEach.call(reviews, function (review) {
          if (review.offsetHeight) max = max > review.offsetHeight ? max : review.offsetHeight;
        });
    
        [].forEach.call(reviews, function (review) {
          review.style.height = max + 'px';
        });
        this.startAutomaticScroll();
        this.ref.detectChanges();
    })
  }

  startAutomaticScroll() {
    setInterval(() => {
      this.scrollUp();
    }, 5000);
  }
  
  scrollUp() {
    this.carousel.nativeElement.scrollTo({ top: (this.carousel.nativeElement.scrollTop + (this.getHeight())), behavior: 'smooth' });
  }

  setOverflow() {
    this.overflowHidden = true;
  }

  getHeight() {
    const height = this.card.nativeElement.offsetHeight;
    return height;
  }

}
