/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { GoogleReviewSliderComponent } from "./google-review-slider.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { GoogleCardModule } from "../google-card/google-card.module";

@NgModule({
  imports: [CommonModule, RouterModule, GoogleCardModule],
  entryComponents: [GoogleReviewSliderComponent],
  declarations: [GoogleReviewSliderComponent],
  exports: [GoogleReviewSliderComponent],
})
export class GoogleReviewSliderModule {}
