
<div class="booking">
  <div class="grid-x booking-wrapper" *ngIf="extra == 'true'">
    <div class="table-wrap small-12 medium-6 columns">
      <table class="table-booking">
        <tr *ngFor="let service of bookingDetail">
          <td>{{ service.service }}</td>
          <td>{{ service.cost }}</td>
          <td>
            <a [href]="service.link"><button class="button" *ngIf="service.link">Book</button></a>
          </td>
        </tr>
      </table>
    </div>
    <div class="notice-wrap small-12 medium-6 columns">
      <div class="notice" *ngIf="extraHeader">
        <h3>
          {{ extraHeader }}
        </h3>
        <p [innerHtml]="extraParagraph"></p>
        <a [href]="extraLink"
          ><button class="button">Book</button></a
        >
      </div>
    </div>
    <!-- <div class="jump-section">
      <a (click)="scrollSmoothToSection('/bookingService#bookNow')" href="#">
        <p><i class="fa fa-chevron-down" aria-hidden="true"></i></p>
      </a>
    </div> -->
  </div>
  <div class="booking-wrapper"  id="bookingTable" *ngIf="extra == 'false'">
    <h1 class="main-heading">Our Services</h1>
    <div class="table-wrap-full ">
      <div class="table-container-full">
        <table class="small-table">
<!--        <thead>-->
<!--        <th>{{ firstTableRowHeader }}</th>-->
<!--        <th>{{ secondTableRowHeader }}</th>-->
<!--        <th>{{ thirdTableRowHeader }}</th>-->
<!--        </thead>-->
        <tr *ngFor="let service of bookingDetail">
          <td>{{ service.service }}</td>
          <td>{{ service.cost }}</td>
          <td>
            <a [href]="service.link"
            ><button class="button" *ngIf="service.link">Book</button></a
            >
          </td>
        </tr>
      </table>
      </div>
      <!-- <div class="jump-section">
        <a (click)="scrollSmoothToSection('/bookingService#bookNow')" href="#">
          <p><i class="fa fa-chevron-down" aria-hidden="true"></i></p>
        </a>
      </div> -->
    </div>
  </div>
</div>
