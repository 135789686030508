<!--<div class="router-outlet">-->
<!--  <div class="hero-container">-->
<!--    <div class="hero-wrapper grid-x">-->
<!--      <div class="text-section small-12 medium-6 columns">-->
<!--        <section class="header">-->
<!--          <h1>{{ header }}</h1>-->
<!--          <h2 class="green-highlight">{{ subheader }}</h2>-->
<!--        </section>-->
<!--        <section class="body">-->
<!--          <p>{{ body }}</p>-->
<!--        </section>-->
<!--        <section class="buttons-wrap">-->
<!--          <p class="important">{{ subbody }}</p>-->

<!--          <section class="reviews">-->
<!--            <div class="desktop">-->
<!--              <img-->
<!--                class="list-brands"-->
<!--                src="/assets/images/brands/list-brands.png"-->
<!--                alt="we are registered with these brands"-->
<!--              />-->
<!--            </div>-->
<!--          </section>-->
<!--        </section>-->
<!--      </div>-->
<!--      <div class="image-section small-12 medium-6 columns">-->
<!--        <img class="shadow-image" src="/assets/images/IMG_0442.jpg" alt="pilate female"/>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="hero-container-about">
  <div class="image-container" [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}">
    <div class="transparent-side">
      <div class="text-content">
        <section class="header">
          <h1>{{ header }}</h1>
          <h2>{{ subheader }}</h2>
        </section>
        <section class="body">
          <p *ngIf="body" [innerHtml]="body"></p>
          <p class="small-text-info" [innerHtml]="subbody"></p>
        </section>
        <!--        <section class="buttons-wrap">-->
        <!--          <button class="button">Book <span class="mobile-hidden">now</span> </button>-->
        <!--        </section>-->

      </div>
    </div>
  </div>
</div>
