import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-header',
  templateUrl: './contact-header.component.html',
  styleUrls: ['./contact-header.component.scss']
})
export class ContactHeaderComponent implements OnInit {

  @Input() public header: string;
  @Input() public subheader: string;
  @Input() public paragraph: string;
  @Input() public telephone: string;
  @Input() public email: string;
  @Input() public imageUrl: string;


  constructor() { }

  ngOnInit(): void {
  }

  scrollSmoothToSection(targetId: string) {
    window.document.getElementById(targetId.split('#')[1]).scrollIntoView({ behavior: 'smooth'});
    return false;
  }

}
