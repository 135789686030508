import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ServiceCardsComponent } from "./service-cards.component";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [ServiceCardsComponent],
  declarations: [ServiceCardsComponent],
  exports: [ServiceCardsComponent],
})
export class ServiceCardsModule {}
