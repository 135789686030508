<div class="contact-wrapper">
  <div>
    <div class="contact-details">
      <div class="email-fields" *ngIf="!isConfirmed">
        <h3>Book for Retreat</h3>
        <p>Fill out the form to reserve your spot. We’ll contact you to finalise your booking with a deposit.</p>

        <div>
          <label for="fullNameEmail">Name:</label>
          <input
            type="text"
            name="fullNameEmail"
            id="fullNameEmail"
            placeholder="john doe"
            [(ngModel)]="fullNameEmail"
          />
          <label for="emailAddress">Email:</label>
          <input
            type="text"
            name="emailAddress"
            id="emailAddress"
            placeholder="john-doe@mail.com"
            [(ngModel)]="emailAddress"
          />
          <label for="phoneNo">Mobile number:</label>
          <input
            type="text"
            name="phoneNo"
            id="phoneNo"
            placeholder="123-123-1234"
            [(ngModel)]="phoneNo"
          />
          <!-- <label for="address">Address:</label>
          <input
            type="text"
            name="address"
            id="address"
            placeholder="12 Ave, Luke st."
            [(ngModel)]="address"
          /> -->
          <!-- <label for="dateOfBirth">Date Of Birth:</label>
          <input
            type="text"
            name="dateOfBirth"
            id="dateOfBirth"
            placeholder="23-Feb-1990"
            [(ngModel)]="dateOfBirth"
          /> -->
          <label for="emailAddress">Room Preference</label>
          <div class="select-radios">
            <label class="container"
              >Private Room
              <input type="radio" checked="checked" [(ngModel)]="roomType" />
              <span class="checkmark"></span>
            </label>

            <label class="container" style="margin-left: 20px"
              >Shared Room
              <input type="checkbox" [(ngModel)]="roomType" />
              <span class="checkmark"></span>
            </label>
          </div>
          <!-- <label for="roomMate">Name of person you are sharing with</label>
          <input
            type="text"
            name="roomMate"
            id="roomMate"
            placeholder="John Joe"
            [(ngModel)]="roomMate"
          /> -->
          <label for="message">Dietary requirements. Give details;</label>
          <textarea
            type="text"
            name="dietary"
            id="dietary"
            placeholder="Vegetarian/Pescetarian/Vegan/Carnivores "
            [(ngModel)]="dietary"
          ></textarea>
          <label for="injuries">Any injuries or health conditions:</label>
          <textarea
            type="text"
            name="injuries"
            id="injuries"
            placeholder="Enter here ..."
            [(ngModel)]="injuries"
          ></textarea>
          <label for="experience"
            >Previous Yoga/Pilates Experience </label
          >
          <!-- <textarea
            type="text"
            name="experience"
            id="experience"
            placeholder="Enter here ..."
            [(ngModel)]="experience"
          ></textarea> -->

          <div class="select-radios">
            <label class="container">
              Yes
              <input type="radio" name="experience" [(ngModel)]="experience" [value]="true" />
              <span class="checkmark"></span>
            </label>
          
            <label class="container" style="margin-left: 20px">
              No
              <input type="radio" name="experience" [(ngModel)]="experience" [value]="false" />
              <span class="checkmark"></span>
            </label>
          </div>
          


        </div>
        <button (click)="submitContactRequest('Email Request')" class="button">
          Secure Your Spot
        </button>
      </div>
      <div class="confirmed" *ngIf="isConfirmed">
        <h3>Booking Confirmation</h3>
        <p>Yoga and Pilates Retreat 16 th -23 rd September 2023</p>
        <br />
        <a
          class="download-link"
          href="src/assets/docs/BookingConfirmation2023.pdf"
          download
        >
          Download Confirmation
          <i class="fa fa-check-square-o" aria-hidden="true"></i>
        </a>

        <p>
          Thank you for booking onto my Retreat. I am really looking forward to
          our week together.
        </p>
        <div class="grid-x">
          <section class="small-12 medium-6 columns">
            <h3>What next?</h3>
            <p>
              Sort Code: 08-92-50<br />
              Account Number: 68693983<br />
              Account type: Business<br />
              Account Name: Inline Health Limited<br />
              Bank: The Co-operative Bank<br />
              Reference: Retreat 2023
            </p>
          </section>
          <section class="requirements small-12 medium-6 columns">
            <h3>What you will need</h3>
            <ul>
              <li>
                Yoga Mat- the surface we practice on is hard so invest in a
                decent mat for comfort
              </li>
              <li>
                Pilates 9” Over Ball and small spikey balls if you have them 8
                or 10c, resistant band.
              </li>
              <li>Yoga strap, 2x blocks (brick shape)</li>
              <li>Any other equipment you usually would use in a class.</li>
              <li>Clothing that is easy to move and stretch in</li>
              <li>
                Long sleeved top in case it is cool in class as we practice
                outside
              </li>
              <li>Snorkel if you like snorkeling</li>
              <li>
                Water shoes- optional but there are a few sea urchins around
              </li>
              <li>Comfortable trainers or walking shoes</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
