import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-flexibility',
  templateUrl: './flexibility.component.html',
  styleUrls: ['./flexibility.component.css']
})
export class FlexibilityComponent implements OnInit {
  @Input() public flexibilityInfo: {
    imageURL: string;

};

  constructor() { }

  ngOnInit(): void {
  }

}
