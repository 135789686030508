/**
 * The Component Module
 */
import {NgModule} from "@angular/core";
import {FlipInfoCardComponent} from './flip-info-card.component';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [FlipInfoCardComponent],
  declarations: [FlipInfoCardComponent],
  exports: [FlipInfoCardComponent]
})
export class FlipInfoCardModule {


}
