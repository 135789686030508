/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { ServiceCardRowComponent } from "./service-card-row.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { StaffCardModule } from "../staff-card/staff-card.module";
import { ServiceCardsModule } from "../service-cards/service-cards.module";

@NgModule({
  imports: [CommonModule, RouterModule, StaffCardModule, ServiceCardsModule],
  declarations: [ServiceCardRowComponent],
  exports: [ServiceCardRowComponent],
})
export class ServiceCardRowModule {}
