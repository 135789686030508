<div class="page-items">
  <!-- <div class="header">
    <div class="header-wrapper">
      <div class="thick-line"></div>
      <section class="info-text">
        <h1>Contact Us</h1>
        <h2 class="green-highlight">At the Inline Health Centre</h2>
        <p>You can contact us in the following ways:</p>
        <p>
          Call:
          <span class="phone">
            <a href="tel: +441622410160">01622 410160</a></span
          >
          | Email:
          <span class="email"
            ><a href="mailto: info@inlinehealth.co.uk"
              >info@inlinehealth.co.uk</a
            ></span
          >
        </p>
      </section>
      <div class="thick-line"></div>
    </div>
  </div> -->

  <app-contact-header></app-contact-header>
  <app-contact-map></app-contact-map>
  <app-contact-jobs></app-contact-jobs>

  <!-- <div class="grid-x">
    <div class="map small-12 medium-6 columns">
      <h2>Find Us</h2>
      <iframe
        class="shadow-image"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1248.0804583118784!2d0.5376972583442924!3d51.27135697297758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df32181237d45b%3A0x1d009371c2379ce1!2sTurkey+Mill%2C+Ashford+Rd%2C+Maidstone+ME14+5PP!5e0!3m2!1sen!2suk!4v1566399069087!5m2!1sen!2suk"
        width="100%"
        height="450"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
      <p class="green-highlight">
        Turkey Mill, Room 3, North Wing, Turkey Mill, Ashford Road, Maidstone,
        ME14 5P
      </p>
    </div>
    <div class="directions small-12 medium-6 columns">
      <h3>Directions to shop and clinic</h3>
      <ul class="direction-list">
        <li>Drive into Turkey Mill along the A20</li>
        <li>
          There is an Orangery sign on the North Wing building just to your
          left, and you will also see a sign with Inline Health Injury Clinic on
          it.
        </li>
        <li>
          You then come to a little roundabout, if you take the 5th exit, a few
          car parking spaces down on the left you will see 2 spaces marked for
          Inline Health. Alternatively you can use the visitor parking.
        </li>
        <li>
          Walk back to the North Wing and through the metal gate, down a few
          stairs you will see a green door. Press the Inline Health buzzer on
          the right and someone will let you in.
        </li>
        <li>
          Go up the stairs and take a seat outside of the room and someone will
          come and greet you when it's time for your treatment.
        </li>
      </ul>
      <div class="call-to">
        <button class="button" (click)="openModal()">Contact Us Now</button>
      </div>
    </div>
  </div>

  <div class="contact">
    <div *ngIf="showContact" id="myModal" #myModal class="modal">
       Modal content

      <div class="modal-content">
        <span (click)="openModal()" class="close">&times;</span>
        <app-contact></app-contact>
      </div>
    </div>
  </div>

  <div class="jobs">
    <h2>Jobs</h2>
    <p>
      If you would like to join our team here at Inline Health Ltd or are
      interested in taking part in events please e-mail us your CV, write about
      the therapy you offer and your availability. We would love to hear from
      you.
    </p>
    <img
      src="/assets/images/brands/job-list.png"
      class="job-list"
      alt="we are registered"
    />
  </div> -->
</div>
