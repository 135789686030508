
  <div class="symbols">
    <h1 class="gradient text-center">Find Us</h1>
    <div class="grid-x">
      <div *ngIf="route.url === '/cambridge-branch' || route.url === '/'" class="branch address-one small-12 medium-4 columns">
        <h3>{{title}}</h3>
        <p>{{paragraph}}</p>
          <a href = "tel: {{telephone}}"><span><i class="fas fa-phone mr-3"></i> {{telephone}}</span></a><br/>
        <img src={{imageURL}} alt="logo" class="logo" />
      </div>

      <div class="location small-12 medium-4 columns">
        <iframe
          _ngcontent-ijg-c13=""
          width="350px"
          height="200px"
          id="mapcanvas"
          src="https://www.google.com/maps/d/u/0/embed?mid=10CPoe3YQCDqQP8sP15hGegbrHxDt8OOB&ehbc=2E312F"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ><div _ngcontent-ijg-c13="" style="overflow: hidden">
          <div
            _ngcontent-ijg-c13=""
            id="gmap_canvas"
            style="height: 200px; width: 350px"
          ></div>
        </div>
          <div _ngcontent-ijg-c13="">
            <small _ngcontent-ijg-c13=""
            >Powered by
              <a _ngcontent-ijg-c13="" href="https://www.embedgooglemap.co.uk"
              >Embed Google Map</a
              ></small
            >
          </div></iframe
        >
      </div>
      <div *ngIf="route.url === '/norwich-branch' || route.url === '/'" class="branch address-two small-12 medium-4 columns">
      <h3>Norwich Branch</h3>
      <p>Aylsham Business Park,<br/> Unit 8 - Richard Oakes Rd,<br/> Aylsham,<br/> Norwich,<br/> NR11 6FD</p>
      <a href = "tel: +441603551204"><span><i class="fas fa-phone mr-3"></i> 01603 551 204</span></a><br/>
        <img src="./assets/east-images/logo.png" alt="logo" class="logo" />
    </div>
</div>