<div *ngIf="small == 'true'" class="small-holder" id="bookNow">
  <div class="small-pic" *ngIf="hideSmallText == 'true'">
    <div class="team-member" *ngFor="let team of teamMember">
      <img
              [src]="team.imageUrl"
              [alt]="'Staff' + team.name"
              class="staff-image"
      />
      <h2 class="green-highlight">{{ team.name }}</h2>
      <h4 class="grey">{{ team.role }}</h4>
        <button class="button" routerLink="/about-alison">Learn More</button>
    </div>
  </div>

  <div *ngIf="hideSmallText == 'false'">
    <div class="background-image-container">
      <div class="grid-x grid-hold">
        <div class="image-section small-12 medium-6 columns">
          <div class="text-hold">
            <h1 class="green-highlight">Book a class with Alison</h1>
            <p>
              Her dedication helps you reach your goals and feel better.
            </p>
          </div>

          <div class="team-member" *ngFor="let team of teamMember">
            <img
                    [src]="team.imageUrl"
                    [alt]="'Staff' + team.name"
                    class="staff-image"
            />
            <h2 class="green-highlight">{{ team.name }}</h2>
            <h4 class="grey">{{ team.role }}</h4>
          </div>
        </div>
        <div class="small-12 medium-6 columns" *ngFor="let team of teamMember">
          <div class="small-text-container">
            <p>
              <span class="bold-grey">Qualifications</span>
              {{ team.qualifications }}
            </p>
            <p *ngIf="team.studies">
              <span class="bold-grey">Further Studies</span> {{ team.studies }}
            </p>

            <div class="button-wrap">
              <button class="button" routerLink="/about-alison">Learn More</button>
            </div>
          </div>

        </div>
      </div>
  </div>

<!--    <div class="jump-section">-->
<!--        <a (click)="scrollSmoothToSection('/bookingService#bookingTable')" href="#">-->
<!--          <p><i class="fa fa-chevron-down" aria-hidden="true"></i></p>-->
<!--        </a>-->
<!--    </div>-->
  </div>
</div>

<div *ngIf="small == 'false'" class="meet-the-team">
  <div *ngFor="let team of teamMember">
    <div class="selected-team-view grid-x">
      <div class="image-container small-12 medium-6 columns">
        <img src="./assets/images/edited/ali-single.png" alt="portrait of alison" />
      </div>
      <div class="text-container small-12 medium-6 columns">
        <h1 class="main-heading">Meet Alison Newsome</h1>

        <p>
          <span class="bold-grey">Qualifications</span>
          {{ team.qualifications }}
        </p>
        <p *ngIf="team.studies">
          <span class="bold-grey">Further Studies</span> {{ team.studies }}
        </p>

        <div class="button-wrap">
          <button class="button" routerLink="/booking">Book now</button>
        </div>
      </div>
    </div>
    <div class="more-text">
      <h1 class="main-heading">About Me</h1>

      <div class="grid-x holder-text">

        <div class="text-pad small-12 medium-6 columns">
          <p [innerHtml]="team.paragraph1"></p>
        </div>
        <div class="text-pad small-12 medium-6 columns">
          <p [innerHtml]="team.paragraph2"></p>
        </div>
      </div>
    </div>

  </div>
</div>
