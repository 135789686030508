import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexibilityComponent } from './flexibility.component';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [FlexibilityComponent],
  declarations: [FlexibilityComponent],
  exports: [FlexibilityComponent]
})
export class FlexibilityModule { }