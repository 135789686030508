  <div class="hero-container">
    <div class="image-container" [ngStyle]="{'background-image': 'url(' + backgroundImage + ')'}">
        <div class="transparent-side">
        <div class="text-content">
            <section class="header">
              <h1>{{ header }}</h1>
              <h2>{{ subheader }}</h2>
            </section>
            <section class="body">
              <p [innerHtml]="body"></p>
            </section>
            <section class="buttons-wrap">
              <button class="button" routerLink="/booking">Book <span class="mobile-hidden">now</span> </button>
            </section>
        </div>
        </div>
    </div>
  </div>


  <div *ngIf="showWarning" id="myModal" #myModal class="modal">
      <!-- Modal content -->

      <div class="modal-content">
          <span (click)="openModal()" class="close">&times;</span>
          <div class="important-message">
              <h1 class="main-heading">Important Information!</h1>
              <section class="message-content">
                  <p [innerHtml]="notice"></p>


<!--                  <table id="contact">-->
<!--                      <thead>-->
<!--                      <tr>-->
<!--                          <th>Staff</th>-->
<!--                          <th>Email</th>-->
<!--                      </tr>-->
<!--                      </thead>-->

<!--                      <tr>-->
<!--                          <td>Katerina</td>-->
<!--                          <td><a href = "mailto:jkozlovska@yahoo.com">jkozlovska@yahoo.com</a></td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                          <td>Katie</td>-->
<!--                          <td><a href = "mailto:k8richardson1801@gmail.com">k8richardson1801@gmail.com</a></td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                          <td>Alison</td>-->
<!--                          <td><a href = "mailto:alison@inlinehealth.co.uk">alison@inlinehealth.co.uk</a></td>-->
<!--                      </tr>-->
<!--                  </table>-->
              </section>

          </div>
      </div>
  </div>
