import { Component, Input } from "@angular/core";

@Component({
  selector: "app-hero-service",
  templateUrl: "./hero-service.component.html",
  styleUrls: ["./hero-service.component.scss"],
})
export class HeroServiceComponent {
  @Input() public topText?: string;
  @Input() public largeText?: string;
  @Input() public bottomText?: string;
  @Input() public paragraph: string;
  constructor() {}
}
