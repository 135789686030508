import { Component, Input, OnDestroy, OnInit } from "@angular/core";

@Component({
  selector: "app-dropdown-menu",
  templateUrl: "./dropdown-menu.component.html",
  styleUrls: ["./dropdown-menu.component.scss"],
})
export class DropdownMenuComponent implements OnInit, OnDestroy{
  @Input() imageURL: string;
  badgeURL: string = "/assets/images/header-badge.jpeg"
  @Input() dropdown: {
    header: {
      name: string;
      link?: string;
      show?: boolean;
      subheader?: { name: string; link: string }[];
    }[];
  };
  public MenuOpen: boolean = false;
  public burgerMenuOpen: boolean = false;
  public menuInteractionStarted: boolean = false;
  public fixedPosition: boolean = window.scrollY > 200;
  public maxPosition: boolean = window.scrollY > 550;

  constructor() {}

  ngOnInit() {
    // window.addEventListener('scroll', this.checkIfShouldFix, true);
    //Add event listener that checks for scroll
    // window.addEventListener('scroll', this.checkHeight, true);
  }

  ngOnDestroy() {
    // window.removeEventListener('scroll', this.checkIfShouldFix, true);
    // window.addEventListener('scroll', this.checkHeight, true);
  }


  checkHeight = (): void => {
    // console.log('scrolled');
    //entire height of the whole document = this will equal to the end of the page
    // const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    //check how much the user has scrolled = the amount the user has scrolled down
    // const scrolled = window.scrollY;
    // console.log('scrolled');

    this.maxPosition = window.scrollY > 550;
    // console.log(this.maxPosition);
    // alert('HIT');
    // if (Math.ceil(scrolled) === scrollable) {
    //   alert('new section');
    // }

  }


  toggleMenu() {
    this.menuInteractionStarted = true;
    this.burgerMenuOpen = !this.burgerMenuOpen;
    this.MenuOpen = !this.MenuOpen;
  }

  show(index) {
    this.dropdown.header[index].show = !this.dropdown.header[index].show;
  }

  closeBurger() {
    // if (header.subheader && header.subheader.length) {
    //   //Keep burger open if there is a sub menu to show
    // } else {
      this.burgerMenuOpen = false;
    // }
  }
}
