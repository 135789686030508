<div class="section-header">
    <div class="line"></div>

    <h1 class="main-heading glow">Services</h1>
    <div class="line"></div>
</div>
<!--<div class="slider-container">-->
<!--    <div class="outer-wrapper" *ngFor="let service of serviceCardInfo; let i = index;">-->
<!--        <div class="carousel" #carousel>-->
<div *ngFor="let service of serviceCardInfo; let i = index;">
    <div (click)="counter = i" class="grid-x">
        <div class="items small-12 medium-4 columns">
            <app-service-cards id="service-card-1" [serviceCardInfo]="service" class="item" [ngClass]="{'active' : counter}"></app-service-cards>
        </div>
    </div>
</div>

<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-2" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-3" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-4" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-5" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-6" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-7" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-8" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-9" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--            <ng-container *ngFor="let service of serviceCardInfo; let i = index;" (click)="counter = i">-->
<!--                <app-service-cards id="service-card-10" [serviceCardInfo]="service" class="item" ></app-service-cards>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
