<div class="container">
  <div class="image">
    <img src="{{ imageURL }}" alt="Section image" class="circle-icon" />
  </div>
  <div class="text">
    <h4>{{ title }}</h4>
    <p [innerHtml]="description"></p>
  </div>
  <div class="button-wrap" *ngIf="buttonText">
    <button [routerLink]="link" class="servicesButton button">
      {{ buttonText }}
    </button>
  </div>
</div>

