
<div class="container">
  <div class="wrapper">
    <div class="benefit-holder">
      <h1 class="center-text" *ngIf="sectionTitle">{{sectionTitle}}</h1>
      <div class="cardholder grid-x">
        <div class="small-12 medium-4 columns card-grid">
          <section>
            <h3>{{title1}}</h3>
            <p *ngIf="paragraph1" [innerHtml]="paragraph1"></p>
          </section>
        </div>
        <div class="small-12 medium-4 columns card-grid">
          <section>
            <h3>{{title2}}</h3>
            <p *ngIf="paragraph2" [innerHtml]="paragraph2"></p>
          </section>
        </div>
        <div class="small-12 medium-4 columns card-grid">
          <section>
            <h3>{{title3}}</h3>
            <p *ngIf="paragraph3" [innerHtml]="paragraph3"></p>
          </section>
        </div>
        <div class="small-12 medium-4 columns card-grid" *ngIf="title4">
          <section>
            <h3>{{title4}}</h3>
            <p *ngIf="paragraph4" [innerHtml]="paragraph4"></p>
          </section>
        </div>
        <div class="small-12 medium-4 columns card-grid" *ngIf="title5">
          <section>
            <h3>{{title5}}</h3>
            <p *ngIf="paragraph5" [innerHtml]="paragraph5"></p>
          </section>
        </div>
        <div class="small-12 medium-4 columns card-grid" *ngIf="title6">
          <section>
            <h3>{{title6}}</h3>
            <p *ngIf="paragraph6" [innerHtml]="paragraph6"></p>
          </section>
        </div>
      </div>
    </div>

  </div>
</div>
