/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { HeroAboutComponent } from "./hero-about.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ServiceCardSliderModule } from "../service-card-slider/service-card-slider.module";
import { OurValuesModule } from "../our-values/our-values.module";
import { FlexibilityModule } from "../flexibility/flexibility.module";

@NgModule({
  imports: [CommonModule, RouterModule],
  entryComponents: [HeroAboutComponent],
  declarations: [HeroAboutComponent],
  exports: [HeroAboutComponent],
})
export class HeroAboutModule {}
