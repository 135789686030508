/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { HeroServiceComponent } from "./hero-service.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ServiceCardSliderModule } from "../service-card-slider/service-card-slider.module";

@NgModule({
  imports: [CommonModule, RouterModule, ServiceCardSliderModule],
  entryComponents: [HeroServiceComponent],
  declarations: [HeroServiceComponent],
  exports: [HeroServiceComponent],
})
export class HeroServiceModule {}
