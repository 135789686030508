<div class="hero-container">
  <div class="image-container" [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}">
    <div class="transparent-side">
      <div class="text-content">
        <section class="header">
          <h1>{{ header }}</h1>
          <h2>{{ subheader }}</h2>
        </section>
        <section class="info-text">
          <p>{{ paragraph }}</p>
          <p>
            Call:

            <span class="phone">
          <a href="tel: {{ telephone }}">{{ telephone }}</a></span
            >

            | Email:

            <span class="email"
            ><a href="mailto: {{ email }}">{{ email }}</a></span
            >
          </p>
        </section>
        <section class="buttons-wrap">
          <a (click)="scrollSmoothToSection('/contact-us#find')" href="#">
            <button class="button">Find Us</button>
          </a>
        </section>

      </div>
    </div>
  </div>
</div>
