import {Component, HostListener, Input} from '@angular/core';

@Component({
  selector: "app-contact-page",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.scss"],
})
export class ContactPageComponent {

  showContact: boolean;
  constructor() {}

  //Close modal if you click outside of it.
  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (event.target == document.getElementById("myModal")) {
      this.showContact = false;
    }
  }

  //Close modal if you click escape.
  @HostListener("document:keydown", ["$event"])
  onDocumentKeydown(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.showContact = false;
    }
  }

  openModal() {
    this.showContact = !this.showContact;
  }
}


