import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: "app-image-and-description-two",
  templateUrl: "./image-and-description-two.component.html",
  styleUrls: ["./image-and-description-two.component.scss"],
})
export class ImageAndDescriptionTwoComponent implements OnInit {

  @Input() public sectionHeader1: string;
  @Input() public paragraph1: string;
  @Input() public sectionHeader2: string;
  @Input() public paragraph2: string;
  @Input() public sectionVisible: string;
  @Input() public noticeTitle: string;
  @Input() public noticeLink: string;
  @Input() public buttonTitle: string;
  public sportsMassageServices: {
    service: string;
    cost?: string;
    link?: string;

  } [] = [
    // {
    //   service: "Sports Massage",
    // },
    {
      service: "1 hour Sports Massage",
      cost: " £45 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "45mins Sports Massage",
      cost: " £38 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "30mins Sports Massage",
      cost: " £30 ",
      link: "https://inlinehealth.janeapp.co.uk",
    }
  ] ;


  public pilateServices: {
    service: string;
    cost?: string;
    link?: string;

  } [] = [
    // {
    //   service: "Pilates",
    // },
    {
      service: "Single 1:1 class",
      cost: " £40 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Single 1:2 class",
      cost: " £50 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Block of 6, 1:1 classes",
      cost: " £200 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Single group class",
      cost: " £10 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Singe zoom class",
      cost: " £8 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Block of 6 group classes",
      cost: " £52 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Block of 6 zoom classes",
      cost: " £48",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    }
  ];


  public yogaServices: {
    service: string;
    cost?: string;
    link?: string;

  } [] = [
    {
      service: "Single 1:1 class",
      cost: " £40 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Single 1:2 class",
      cost: " £50 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Block of 6, 1:1 classes",
      cost: " £200 ",
      link: "https://inlinehealth.janeapp.co.uk",
    },
    {
      service: "Single group class",
      cost: " £10 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Singe zoom class",
      cost: " £8 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Block of 6 group classes",
      cost: " £52 ",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    },
    {
      service: "Block of 6 zoom classes",
      cost: " £48",
      link: "https://goteamup.com/p/580983-pilates-through-life/",
    }
  ] ;
  constructor() {
    console.log('Visible ', this.sectionVisible);
  }

  ngOnInit(): void {}
}
