<div class="staff-card">
    <div class="image-wrapper border-gradient">
        <img [src]="imageUrl" class="team-image">
    </div>
    <div class="card-container">
        <div class="card-wrapper">
            <div class="text-section">
              <div class="up-text">
                <h2>{{name}}</h2>
                <h3>{{education}}</h3>
              </div>
              <div class="specialism-list" id="specialism">
                <ul>
                  <li *ngIf="specialism1"><a class="specialist" [href]="'/' + specialism1Link"><button class="small-button">{{specialism1}}</button></a></li>
                  <li *ngIf="specialism2"><a class="specialist" [href]="'/' + specialism2Link"><button class="small-button">{{specialism2}}</button></a></li>
                  <li *ngIf="specialism3"><a class="specialist" [href]="'/' + specialism3Link"><button class="small-button">{{specialism3}}</button></a></li>
                  <li *ngIf="specialism4"><a class="specialist" [href]="'/' + specialism4Link"><button class="small-button">{{specialism4}}</button></a></li>
                  <li *ngIf="specialism5"><a class="specialist" [href]="'/' + specialism5Link"><button class="small-button">{{specialism5}}</button></a></li>
                </ul>
              </div>

                <p class="ellipsis" [innerHTML]="description"></p>
                <button (click)="openModal()"  class="learnButton"><span class="showHover"><i class="fas fa-angle-right"></i></span>Learn More <span class="hideHover"><i class="fas fa-angle-left"></i></span></button>
            </div>
        </div>
    </div>
    <div *ngIf="showList" id="myModal" #myModal class="modal">
        <!-- Modal content -->
        
        <div class="modal-content">
          <span (click)="openModal()" class="close">&times;</span>
          <div class="team-modal">
            <div class="image">
                <img [src]="imageUrl" class="team-image">
              </div>
              <div class="text-section">
                <div class="heading">
                  <h2>{{name}}</h2>
                  <h3>{{education}}</h3>
                  <p [innerHTML]="description"></p>
<!--                  <button class="button" (click)="slideRight()">Read more</button>-->
                </div>
                <div class="paragraph" id="nextpage">
                  <p [innerHTML]="extraDescription"></p>
                </div>
            </div>
          </div>
        </div>
      </div>
</div>
