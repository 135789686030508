/**
 * The Component Module
 */
import { NgModule } from "@angular/core";
import { VideosRowComponent } from "./videos-row.component";
import { CommonModule } from "@angular/common";

import { RouterModule } from "@angular/router";


@NgModule({
    imports: [CommonModule, RouterModule],
  entryComponents: [VideosRowComponent],
  declarations: [VideosRowComponent],
  exports: [VideosRowComponent],
})
export class VideosRowModule {}
